import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import LayoutLanding from "../../components/layout-landing"
import Seo from "../../components/seo"
import BirthdaySmsHero from "../../components/birthday-sms-hero"

const BirthdaySmsPage = ({ location }) => (
  <LayoutLanding>
    <Seo location={location} title="Wish John Goncalves a Happy Birthday!" />
    <BirthdaySmsHero />
  </LayoutLanding>
)

export default BirthdaySmsPage
